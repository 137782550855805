exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cafe-thanks-js": () => import("./../../../src/pages/cafe_thanks.js" /* webpackChunkName: "component---src-pages-cafe-thanks-js" */),
  "component---src-pages-chi-2-js": () => import("./../../../src/pages/chi2.js" /* webpackChunkName: "component---src-pages-chi-2-js" */),
  "component---src-pages-chi-js": () => import("./../../../src/pages/chi.js" /* webpackChunkName: "component---src-pages-chi-js" */),
  "component---src-pages-chinchilla-importance-js": () => import("./../../../src/pages/chinchilla_importance.js" /* webpackChunkName: "component---src-pages-chinchilla-importance-js" */),
  "component---src-pages-chinchilla-rearing-js": () => import("./../../../src/pages/chinchilla_rearing.js" /* webpackChunkName: "component---src-pages-chinchilla-rearing-js" */),
  "component---src-pages-chinchilla-thanks-js": () => import("./../../../src/pages/chinchilla_thanks.js" /* webpackChunkName: "component---src-pages-chinchilla-thanks-js" */),
  "component---src-pages-contact-c-page-js": () => import("./../../../src/pages/contact_c_page.js" /* webpackChunkName: "component---src-pages-contact-c-page-js" */),
  "component---src-pages-contact-cafe-js": () => import("./../../../src/pages/contact_cafe.js" /* webpackChunkName: "component---src-pages-contact-cafe-js" */),
  "component---src-pages-contact-hotel-js": () => import("./../../../src/pages/contact_hotel.js" /* webpackChunkName: "component---src-pages-contact-hotel-js" */),
  "component---src-pages-contact-page-js": () => import("./../../../src/pages/contact_page.js" /* webpackChunkName: "component---src-pages-contact-page-js" */),
  "component---src-pages-fremishgiant-page-js": () => import("./../../../src/pages/fremishgiant_page.js" /* webpackChunkName: "component---src-pages-fremishgiant-page-js" */),
  "component---src-pages-fremishgiant-thanks-js": () => import("./../../../src/pages/fremishgiant_thanks.js" /* webpackChunkName: "component---src-pages-fremishgiant-thanks-js" */),
  "component---src-pages-herbivore-meal-js": () => import("./../../../src/pages/herbivore_meal.js" /* webpackChunkName: "component---src-pages-herbivore-meal-js" */),
  "component---src-pages-hotel-js": () => import("./../../../src/pages/hotel.js" /* webpackChunkName: "component---src-pages-hotel-js" */),
  "component---src-pages-hotel-thanks-js": () => import("./../../../src/pages/hotel_thanks.js" /* webpackChunkName: "component---src-pages-hotel-thanks-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-price-js": () => import("./../../../src/pages/price.js" /* webpackChunkName: "component---src-pages-price-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-rabbit-breeding-js": () => import("./../../../src/pages/rabbit_breeding.js" /* webpackChunkName: "component---src-pages-rabbit-breeding-js" */),
  "component---src-pages-rabbit-rearing-js": () => import("./../../../src/pages/rabbit_rearing.js" /* webpackChunkName: "component---src-pages-rabbit-rearing-js" */),
  "component---src-pages-rabbit-vegetable-js": () => import("./../../../src/pages/rabbit_vegetable.js" /* webpackChunkName: "component---src-pages-rabbit-vegetable-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-toiawase-thanks-js": () => import("./../../../src/pages/toiawase_thanks.js" /* webpackChunkName: "component---src-pages-toiawase-thanks-js" */),
  "component---src-pages-usagicafe-js": () => import("./../../../src/pages/usagicafe.js" /* webpackChunkName: "component---src-pages-usagicafe-js" */),
  "component---src-pages-voice-js": () => import("./../../../src/pages/voice.js" /* webpackChunkName: "component---src-pages-voice-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-posts-js": () => import("./../../../src/templates/posts.js" /* webpackChunkName: "component---src-templates-posts-js" */)
}

